export default {
  "welcomeMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Witamy w Dropy EBM"])},
  "registerButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarejestruj się"])},
  "clientRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz tutaj swoje darmowe konto"])},
  "completeRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zakończ rejestrację"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Język"])},
  "fName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imię"])},
  "lName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwisko"])},
  "cName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa firmy"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
  "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NIP"])},
  "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulica"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miasto"])},
  "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kraj"])},
  "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod pocztowy"])},
  "radius": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promień"])},
  "agreeTerm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zgadzam się z warunkami korzystania z serwisu"])},
  "accountCreateMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twoje konto zostało utworzone. Sprawdź swój e-mail, aby zweryfikować konto."])},
  "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sukces"])},
  "selectClientGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz grupę klientów"])},
  "Supplier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostawca"])},
  "Suppliers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostawcy"])},
  "Supplierorders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zamówienia dostawcy"])},
  "Dropshipper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dropshipper"])},
  "Manufactor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Producent"])},
  "Craftsman": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rzemieślnik"])},
  "Carrier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przewoźnik"])},
  "Agency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agencja"])},
  "Integrator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrator"])},
  "requiredField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pole jest wymagane"])},
  "uploadFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejestracja działalności gospodarczej"])},
  "loginHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaloguj się na swoje konto"])},
  "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapomniałeś hasła?"])},
  "logIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaloguj się"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasło"])},
  "otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OTP"])},
  "noEmailAccountError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak konta powiązanego z tym adresem e-mail"])},
  "invalidPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieprawidłowe hasło"])},
  "Deliveryaddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres dostawy"])},
  "Invoiceaddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres faktury"])},
  "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zamówienie"])},
  "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zamówienia"])},
  "Orderdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data zamówienia"])},
  "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wysyłka"])},
  "Shippingdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data wysyłki"])},
  "deliverynotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uwagi do dostawy"])},
  "warehouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magazyn"])},
  "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ustawienia"])},
  "stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapas"])},
  "salution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozdrowienie"])},
  "deliveryaddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres dostawy"])},
  "shippingdetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szczegóły wysyłki"])},
  "shippingart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rodzaj wysyłki"])},
  "Shippingarts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rodzaje wysyłki"])},
  "shipper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spedytor"])},
  "shipping_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data wysyłki"])},
  "shipperdetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dane spedytora"])},
  "Articlefeatures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cechy artykułu"])},
  "HOUSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dom"])},
  "deliveryPlacement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miejsce dostawy"])},
  "packageRemoval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usunięcie paczki"])},
  "YES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tak"])},
  "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie"])},
  "PLACE_OF_USE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miejsce użytkowania"])},
  "TWENTYFOUR_HOUR_SERVICE_MO_FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usługa 24h Pn.-Pt."])},
  "TWENTYFOUR_HOUR_SERVICE_SA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usługa 24h Sob."])},
  "FOURTYEIGHT_HOUR_SERVICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usługa 48h"])},
  "DELIVERY_ON_DESIRED_DATE_MO_FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostawa na żądany termin Pn.-Pt."])},
  "DELIVERY_ON_DESIRED_DATE_SA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostawa na żądany termin Sob."])},
  "AFTER_WORK_DELIVERY_SERVICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostawa po godzinach pracy"])},
  "EXPRESS_ASSEMBLY_SERVICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usługa ekspresowego montażu"])},
  "shipmentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metoda wysyłki"])},
  "transferPoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punkt transferowy"])},
  "NORMAL_SHIPMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normalna wysyłka"])},
  "process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proces"])},
  "trackandtrace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Śledzenie przesyłki"])},
  "packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paczki"])},
  "collie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolli"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akcja"])},
  "save_shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz dane wysyłki"])},
  "save_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz adres"])},
  "shippingoptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opcje wysyłki"])},
  "COLLECTION_AT_DEPOT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odbiór w magazynie"])},
  "HUB_LOEHNE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HUB Löhne"])},
  "HUB_ANSBACH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HUB Ansbach"])},
  "HUB_GOLUSKI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HUB Goluski"])},
  "ZUP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZUP Mosina PL"])},
  "DDL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DDL"])},
  "foridentcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Śledzenie dla"])},
  "shippingpositions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozycje wysyłki"])},
  "MS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pani"])},
  "UNKNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieznany"])},
  "COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firma"])},
  "MR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pan"])},
  "DIVERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inne"])},
  "create delivery order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz zlecenie wysyłki"])},
  "save address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz adres"])},
  "MyInterfaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moje interfejsy"])},
  "usedinterfaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Używane interfejsy"])},
  "availableinterfaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostępne interfejsy"])},
  "book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarezerwuj"])},
  "configure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skonfiguruj"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz"])},
  "startjob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozpocznij synchronizację"])},
  "Table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabela"])},
  "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wartość"])},
  "option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opcja"])},
  "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Źródło"])},
  "lastupdatedate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostatnia aktualizacja"])},
  "source value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wartość źródłowa"])},
  "statustype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rodzaj statusu"])},
  "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akcje"])},
  "Contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakty"])},
  "articles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artykuły"])},
  "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie"])},
  "Articleprojects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tworzenie artykułów"])},
  "producer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Producent"])},
  "Warehouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magazyn"])},
  "Returns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zwroty"])},
  "Value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wartość"])},
  "shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wysłane"])},
  "not shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie wysłane"])},
  "saveCollieDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz kolli po dodaniu mapowania"])},
  "saveMappingDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz mapowanie tutaj"])},
  "superAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dropy Admin"])},
  "articleTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szablon"])},
  "shipmentFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wysyłka dla"])},
  "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Użytkownicy"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyloguj się"])},
  "reporting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analizy"])},
  "TrackandTrace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Śledzenie"])},
  "shipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wysyłka"])},
  "shipments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wysyłki"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystkie"])},
  "recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostatnio dodane"])},
  "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oczekujące"])},
  "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywne"])},
  "delivered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostarczone"])},
  "pissue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paczka z problemem"])},
  "returns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zwroty"])},
  "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dzisiaj"])},
  "yeasterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wczoraj"])},
  "thisWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W tym tygodniu"])},
  "lastWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W zeszłym tygodniu"])},
  "last7Days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostatnie 7 dni"])},
  "last14Days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostatnie 14 dni"])},
  "last30Days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostatnie 30 dni"])},
  "thisMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W tym miesiącu"])},
  "lastMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W zeszłym miesiącu"])},
  "last3Month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostatnie 3 miesiące"])},
  "last6Month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostatnie 6 miesięcy"])},
  "last12Month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostatnie 12 miesięcy"])},
  "notPrepared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieprzygotowane"])},
  "returned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zwrócone"])},
  "deliveryException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyjątek dostawy"])},
  "issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problem"])},
  "trackingNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numer śledzenia"])},
  "carrierR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przewoźnik (referencja)"])},
  "lEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostatnie zdarzenie"])},
  "lInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostatnia informacja"])},
  "dateAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data dodania"])},
  "noFilterApplied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie zastosowano filtrów"])},
  "OverviewlastDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przegląd ostatnich 14 dni"])},
  "parcelAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodano paczkę"])},
  "topCarriers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Najlepsi przewoźnicy (na podstawie wolumenu)"])},
  "pendingorders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oczekujące zamówienia"])},
  "profit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zysk"])},
  "orderstoday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dzisiejsze zamówienia"])},
  "earningstoday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dzisiejsze zarobki"])},
  "Order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zamówienie"])},
  "description_success_connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Połączenie udane"])},
  "shippinglog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logi wysyłki"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiadomość"])},
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rodzaj"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
  "LABEL_CREATED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etykieta utworzona"])},
  "selectArticleType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz typ artykułu"])},
  "pim": {
    "articleoverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przegląd artykułu"])},
    "article": {
      "basicdetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dane podstawowe"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa artykułu"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opis artykułu"])},
      "logdata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dane logistyczne"])},
      "prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ceny"])},
      "images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zdjęcia"])},
      "syncArticle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synchronizuj artykuły"])}
    },
    "template": {
      "articleProjectDescriptionTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szablony opisów projektów artykułów"])},
      "createNewTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz nowy szablon"])},
      "saveTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz szablon"])},
      "templateName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa szablonu"])}
    },
    "articleCreation": {
      "articleProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt artykułu"])},
      "addArticleProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj projekt artykułu"])},
      "saveProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz projekt"])}
    }
  },
  "supplier": {
    "articlename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa artykułu dostawcy"])}
  },
  "tt": {
    "noFilterApplied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie ustawiono żadnego filtra"])},
    "TotalShippings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Łączne wysyłki"])}
  },
  "microsoftLogIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaloguj się za pomocą Microsoft 365"])},
  "dropyAdmin": {
    "contract": {
      "contractOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przegląd umowy"])},
      "addContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj umowę"])},
      "contractName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa umowy"])},
      "validFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ważny od"])},
      "validTill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ważny do"])},
      "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wymagane"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa"])},
      "isActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jest aktywny"])},
      "isRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jest wymagany"])},
      "clientGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupa klientów"])},
      "saveContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz umowę"])},
      "selectActiveStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz status aktywności"])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywny"])},
      "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieaktywny"])},
      "selectRequiredStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz status wymagalności"])},
      "notRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niewymagany"])},
      "editContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edytuj umowę"])},
      "updateContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaktualizuj umowę"])},
      "contractDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szczegóły umowy"])}
    }
  },
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zamknij"])},
  "notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powiadomienia"])},
  "markAsRead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oznacz jako przeczytane"])},
  "loadMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Załaduj więcej"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szukaj"])},
  "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resetuj"])},
  "selectlanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz język"])},
  "orm": {
    "order": {
      "syncOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synchronizuj zamówienia"])},
      "selectShop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz sklep"])},
      "orderId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID zamówienia"])},
      "orderDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data zamówienia"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klient"])},
      "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwota"])},
      "fulfillmentStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status realizacji"])},
      "deliveryId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID dostawy"])},
      "deliveryMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metoda dostawy"])},
      "tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag"])}
    }
  },
  "purchase": {
    "supplier": {
      "addSupplier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj dostawcę"])},
      "supplierId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID dostawcy"])},
      "dropshipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dropshipping"])},
      "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strona internetowa"])},
      "interface": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interfejs"])},
      "supplierName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa dostawcy"])},
      "searchBySupplierName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szukaj według nazwy dostawcy"])}
    },
    "supplierOrder": {
      "totalSupplierOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Łączne zamówienia dostawców"])},
      "dateId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID daty"])},
      "drop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zniżka"])},
      "ids": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDS"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klient"])}
    }
  },
  "print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drukuj"])},
  "htmlTemplateConstructor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konstruktor szablonów HTML"])},
  "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podgląd"])},
  "vendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprzedawca"])},
  "createdBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utworzone przez"])},
  "updatedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaktualizowane przez"])},
  "Articlefeaturesvalue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wartość cech artykułu"])},
  "orderOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przegląd zamówień"])},
  "last15Order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostatnie 15 zamówień"])},
  "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanał"])},
  "articledetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szczegóły artykułu"])},
  "descriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opisy"])},
  "articleBundle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pakiet artykułów"])},
  "statistic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statystyki"])},
  "ship": {
    "deliveryNote": {
      "generateLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wygeneruj etykietę"])},
      "downloadLabels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pobierz etykiety"])},
      "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtr"])},
      "shippers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przewoźnicy"])},
      "shippingArt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rodzaj wysyłki"])},
      "outboundStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status wysyłki"])},
      "shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sklep"])},
      "items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozycje"])},
      "superAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Super Admin"])},
      "createShippment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz wysyłkę"])},
      "onHold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wstrzymane"])},
      "editOutbound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edytuj wysyłkę"])},
      "createFullfilment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz realizację"])},
      "filterIssued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtruj problemy"])}
    },
    "shipping": {
      "bulkShippingUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zbiorcza aktualizacja wysyłki"])},
      "shippingOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przegląd wysyłki"])},
      "enableUpdateOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Włącz aktualizację zamówienia"])},
      "selectType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz typ"])},
      "digital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cyfrowy"])},
      "selectFulfilledStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz status realizacji"])},
      "fulfilled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrealizowane"])},
      "notFulfilled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie zrealizowane"])},
      "selectTrackingStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz status śledzenia"])},
      "identcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod identyfikacyjny"])},
      "deliverNr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numer dostawy"])},
      "extReferenceId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zewnętrzny ID referencyjny"])},
      "updateOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaktualizuj zamówienie"])},
      "updateShipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaktualizuj wysyłkę"])},
      "enableFulfillOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Włącz realizację zamówienia"])},
      "fulfillOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrealizuj zamówienie"])}
    }
  },
  "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz"])},
  "Not Created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie utworzono"])},
  "Open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otwarte"])},
  "Confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potwierdzone"])},
  "In Process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W trakcie realizacji"])},
  "Shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wysłane"])},
  "clearFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyczyść filtry"])},
  "clearFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyczyść filtr"])},
  "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zastosuj"])},
  "dateFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtr daty"])},
  "filteredBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrowane przez"])},
  "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waluta"])},
  "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentarz"])},
  "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtr"])},
  "totalShip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Łączne wysyłki"])},
  "contact": {
    "contactO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przegląd kontaktów"])},
    "customerN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numer klienta"])},
    "customerC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoria klienta"])},
    "customerG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupa klientów"])}
  },
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
  "zipCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod pocztowy"])},
  "addChannelSKU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj SKU kanału"])},
  "sku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SKU"])},
  "gtin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GTIN"])},
  "ean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EAN"])},
  "article": {
    "logdata": {
      "collieData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dane Kolli"])},
      "addCollie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj Kolli"])},
      "editCollie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edytuj Kolli"])}
    },
    "stock": {
      "warehouseArticles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artykuły magazynowe"])},
      "updateBuffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaktualizuj bufor"])},
      "totalQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Całkowita ilość"])},
      "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referencja"])},
      "availableQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostępna ilość"])},
      "lastUpdatedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostatnia aktualizacja"])}
    }
  },
  "supplierorder": {
    "addOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj zamówienie"])},
    "refId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID referencyjne"])},
    "supOrdNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numer zamówienia dostawcy"])},
    "selectArticles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz artykuły"])},
    "createOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz zamówienie"])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "saddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres dostawcy"])},
    "iStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status przyjęcia"])},
    "createInbound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz przyjęcie"])},
    "inboundId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID przyjęcia"])}
  },
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anuluj"])},
  "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gotowe"])},
  "articleImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zdjęcie artykułu"])},
  "syncImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synchronizuj zdjęcie"])},
  "orderStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status zamówienia"])},
  "deliverynote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nota dostawy"])},
  "deliveryStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status dostawy"])},
  "uploadImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prześlij zdjęcie"])},
  "articleNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numer artykułu"])},
  "updateOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaktualizuj zamówienie"])},
  "updateExyternalOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaktualizuj zewnętrzne zamówienie"])},
  "printOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drukuj zamówienie"])},
  "freePos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Darmowe pozycje"])},
  "shippingNotRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wysyłka niewymagana"])},
  "noShippingArt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak dostępnego rodzaju wysyłki"])},
  "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usunięte"])},
  "subTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suma częściowa"])},
  "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zniżka"])},
  "taxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podatki"])},
  "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suma"])},
  "custInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informacje kontaktowe"])},
  "shippingAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres wysyłki"])},
  "selectImages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz zdjęcia"])},
  "selectAllImages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz wszystkie zdjęcia"])},
  "deleteImages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń zdjęcia"])},
  "selectcurrency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz walutę"])},
  "stocks": {
    "addWarehouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj magazyn"])},
    "saveWarehouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz magazyn"])}
  },
  "invoice": {
    "invoiceBuilder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kreator faktur"])},
    "nextStep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Następny krok"])},
    "prevStep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poprzedni krok"])},
    "selectCust": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz klienta"])},
    "searchCust": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyszukaj klienta"])},
    "clientDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szczegóły klienta"])}
  },
  "addArticle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj artykuł"])},
  "saveArticle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz artykuł"])},
  "articleType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ artykułu"])},
  "uploadArticleImages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prześlij zdjęcia artykułu"])},
  "addVariant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj wariant"])},
  "variants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warianty"])},
  "selling_price_net": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cena sprzedaży"])},
  "globalSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ustawienia globalne"])},
  "noranges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zakresy numerów"])},
  "prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prefiks"])},
  "currentNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualny numer"])},
  "suffix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sufiks"])},
  "maximum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksimum"])},
  "global": {
    "Invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faktura"])},
    "Credit Note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nota kredytowa"])},
    "Order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zamówienie"])},
    "Quotation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oferta"])},
    "Article": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artykuł"])},
    "Customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klient"])},
    "Price Inquiry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapytanie o cenę"])},
    "Incoming Invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faktura przychodząca"])},
    "Transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer"])},
    "Returns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zwroty"])},
    "Ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilet"])},
    "Pick List": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista kompletacyjna"])},
    "Subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subskrypcja"])},
    "Operation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operacja"])},
    "Ressource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zasób"])},
    "Production Order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zlecenie produkcyjne"])},
    "Production Supply Order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zamówienie na dostawy produkcyjne"])},
    "Production Transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer produkcji"])},
    "Production Batch Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numer partii produkcyjnej"])},
    "Cancellation Invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anulowana faktura"])},
    "Cancellation Credit Note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anulowana nota kredytowa"])},
    "Article Project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt artykułu"])},
    "Claim Order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zamówienie reklamacyjne"])}
  }
}