export default {
  "welcomeMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ड्रॉपी EBM में आपका स्वागत है"])},
  "registerButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपना पंजीकरण करें"])},
  "clientRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यहां अपना मुफ्त खाता बनाएं"])},
  "completeRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पंजीकरण पूरा करें"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भाषा"])},
  "fName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रथम नाम"])},
  "lName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अंतिम नाम"])},
  "cName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कंपनी का नाम"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ईमेल"])},
  "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वैट"])},
  "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सड़क"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शहर"])},
  "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["देश"])},
  "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पोस्टकोड"])},
  "radius": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["त्रिज्या"])},
  "agreeTerm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मैं साइट की शर्तों और नियमों से सहमत हूं"])},
  "accountCreateMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपका खाता बनाया गया है। कृपया अपने खाते की पुष्टि करने के लिए अपना ईमेल जांचें।"])},
  "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सफलता"])},
  "selectClientGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ग्राहक समूह चुनें"])},
  "Supplier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रदायक"])},
  "Suppliers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रदायक"])},
  "Supplierorders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रदायक आदेश"])},
  "Dropshipper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ड्रॉप शिपर"])},
  "Manufactor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निर्माता"])},
  "Craftsman": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कारीगर"])},
  "Carrier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विक्रेता"])},
  "Agency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एजेंसी"])},
  "Integrator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इंटीग्रेटर"])},
  "requiredField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फील्ड अनिवार्य है"])},
  "uploadFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["व्यवसाय पंजीकरण"])},
  "loginHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपने खाते में लॉग इन करें"])},
  "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्या आप अपना पासवर्ड भूल गए?"])},
  "logIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लॉग इन करें"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पासवर्ड"])},
  "otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OTP"])},
  "noEmailAccountError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस ईमेल के साथ कोई खाता जुड़ा नहीं है"])},
  "invalidPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अमान्य पासवर्ड"])},
  "Deliveryaddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वितरण पता"])},
  "Invoiceaddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["चालान पता"])},
  "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आदेश"])},
  "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आदेश"])},
  "Orderdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आदेश तिथि"])},
  "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शिपिंग"])},
  "Shippingdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शिपिंग तिथि"])},
  "deliverynotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वितरण नोट्स"])},
  "warehouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["गोदाम"])},
  "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सेटिंग्स"])},
  "stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्टॉक"])},
  "salution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अभिवादन"])},
  "deliveryaddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वितरण पता"])},
  "shippingdetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शिपिंग विवरण"])},
  "shippingart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शिपिंग कला"])},
  "Shippingarts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शिपिंग कलाएं"])},
  "shipper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शिपर"])},
  "shipping_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शिपिंग तिथि"])},
  "shipperdetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शिपर विवरण"])},
  "Articlefeatures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेख सुविधाएं"])},
  "HOUSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["घर"])},
  "deliveryPlacement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वितरण स्थान"])},
  "packageRemoval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पैकेज हटाना"])},
  "YES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हाँ"])},
  "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नहीं"])},
  "PLACE_OF_USE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोग का स्थान"])},
  "TWENTYFOUR_HOUR_SERVICE_MO_FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24 घंटे सेवा सोमवार-शुक्रवार"])},
  "TWENTYFOUR_HOUR_SERVICE_SA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24 घंटे सेवा शनिवार"])},
  "FOURTYEIGHT_HOUR_SERVICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["48 घंटे सेवा"])},
  "DELIVERY_ON_DESIRED_DATE_MO_FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वांछित तिथि पर वितरण सोमवार-शुक्रवार"])},
  "DELIVERY_ON_DESIRED_DATE_SA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वांछित तिथि पर वितरण शनिवार"])},
  "AFTER_WORK_DELIVERY_SERVICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्य के बाद वितरण सेवा"])},
  "EXPRESS_ASSEMBLY_SERVICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक्सप्रेस असेंबली सेवा"])},
  "shipmentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शिपिंग विधि"])},
  "transferPoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थानांतरण बिंदु"])},
  "NORMAL_SHIPMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सामान्य शिपमेंट"])},
  "process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रक्रिया"])},
  "trackandtrace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वितरण स्थिति"])},
  "packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पैकेज"])},
  "collie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कॉल्ली"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थिति"])},
  "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रिया"])},
  "save_shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शिपिंग डेटा सहेजें"])},
  "save_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पता सहेजें"])},
  "shippingoptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शिपिंग विकल्प"])},
  "COLLECTION_AT_DEPOT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डिपो में संग्रह"])},
  "HUB_LOEHNE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हब लोहेन"])},
  "HUB_ANSBACH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हब अंसबच"])},
  "HUB_GOLUSKI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हब गोलुस्की"])},
  "ZUP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जुप मोसीना PL"])},
  "DDL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DDL"])},
  "foridentcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ट्रैक और ट्रेस के लिए"])},
  "shippingpositions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शिपिंग स्थिति"])},
  "MS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["श्रीमती"])},
  "UNKNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अज्ञात"])},
  "COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कंपनी"])},
  "MR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["श्रीमान"])},
  "DIVERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विविध"])},
  "create delivery order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शिपिंग आदेश बनाएं"])},
  "save address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पता सहेजें"])},
  "MyInterfaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मेरे इंटरफेस"])},
  "usedinterfaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोग किए गए इंटरफेस"])},
  "availableinterfaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपलब्ध इंटरफेस"])},
  "book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बुक"])},
  "configure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कॉन्फ़िगर करें"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सहेजें"])},
  "startjob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समकालिकरण प्रारंभ करें"])},
  "Table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तालिका"])},
  "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल्य"])},
  "option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विकल्प"])},
  "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्रोत"])},
  "lastupdatedate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अंतिम अपडेट"])},
  "source value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्रोत मूल्य"])},
  "statustype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थिति प्रकार"])},
  "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रियाएं"])},
  "Contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संपर्क"])},
  "articles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेख"])},
  "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["श्रेणियाँ"])},
  "Articleprojects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेख निर्माण"])},
  "producer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निर्माता"])},
  "Warehouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["गोदाम"])},
  "Returns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वापसी"])},
  "Value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल्य"])},
  "shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भेजा गया"])},
  "not shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नहीं भेजा गया"])},
  "saveCollieDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मैपिंग जोड़ने के बाद कॉल्ली सहेजें"])},
  "saveMappingDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मैपिंग यहां सहेजें"])},
  "superAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ड्रॉपी व्यवस्थापक"])},
  "articleTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टेम्पलेट"])},
  "shipmentFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शिपिंग के लिए"])},
  "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगकर्ता"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लॉगआउट"])},
  "reporting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विश्लेषण"])},
  "TrackandTrace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ट्रैक और ट्रेस"])},
  "shipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शिपमेंट"])},
  "shipments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शिपमेंट"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी"])},
  "recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हाल में जोड़ा गया"])},
  "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लंबित"])},
  "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सक्रिय"])},
  "delivered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वितरित"])},
  "pissue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समस्या के साथ पार्सल"])},
  "returns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वापसी"])},
  "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आज"])},
  "yeasterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कल"])},
  "thisWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस सप्ताह"])},
  "lastWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पिछले सप्ताह"])},
  "last7Days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पिछले 7 दिन"])},
  "last14Days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पिछले 14 दिन"])},
  "last30Days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पिछले 30 दिन"])},
  "thisMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस माह"])},
  "lastMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पिछले महीने"])},
  "last3Month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पिछले 3 महीने"])},
  "last6Month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पिछले 6 महीने"])},
  "last12Month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पिछले 12 महीने"])},
  "notPrepared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तैयार नहीं किया गया"])},
  "returned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वापसी"])},
  "deliveryException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वितरण अपवाद"])},
  "issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मुद्दा"])},
  "trackingNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ट्रैकिंग नंबर"])},
  "carrierR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विक्रेता (संदर्भ)"])},
  "lEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अंतिम घटना"])},
  "lInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अंतिम जानकारी"])},
  "dateAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जोड़े जाने की तिथि"])},
  "noFilterApplied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई फ़िल्टर लागू नहीं किया गया"])},
  "OverviewlastDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पिछले 14 दिनों का अवलोकन"])},
  "parcelAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पार्सल जोड़ा गया"])},
  "topCarriers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शीर्ष विक्रेता (मात्रा के आधार पर)"])},
  "pendingorders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लंबित आदेश"])},
  "profit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लाभ"])},
  "orderstoday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आज के आदेश"])},
  "earningstoday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आज की कमाई"])},
  "Order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आदेश"])},
  "description_success_connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कनेक्शन सफलतापूर्वक"])},
  "shippinglog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शिपिंग लॉग"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संदेश"])},
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रकार"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तिथि"])},
  "LABEL_CREATED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेबल बनाया गया"])},
  "selectArticleType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेख प्रकार चुनें"])},
  "pim": {
    "articleoverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेख का अवलोकन"])},
    "article": {
      "basicdetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मुख्य जानकारी"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेख का नाम"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेख का विवरण"])},
      "logdata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लॉजिस्टिकल डेटा"])},
      "prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कीमतें"])},
      "images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["चित्र"])},
      "syncArticle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेखों को समकालिक करें"])}
    },
    "template": {
      "articleProjectDescriptionTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेख परियोजना विवरण टेम्पलेट"])},
      "createNewTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नया टेम्पलेट बनाएं"])},
      "saveTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टेम्पलेट सहेजें"])},
      "templateName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टेम्पलेट नाम"])}
    },
    "articleCreation": {
      "articleProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेख परियोजना"])},
      "addArticleProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेख परियोजना जोड़ें"])},
      "saveProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["परियोजना सहेजें"])}
    }
  },
  "tt": {
    "noFilterApplied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई फ़िल्टर सेट नहीं किया गया"])},
    "TotalShippings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कुल शिपमेंट"])}
  },
  "microsoftLogIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["माइक्रोसॉफ्ट 365 के साथ लॉगिन करें"])},
  "dropyAdmin": {
    "contract": {
      "contractOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अनुबंध का अवलोकन"])},
      "addContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अनुबंध जोड़ें"])},
      "contractName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अनुबंध का नाम"])},
      "validFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["से मान्य"])},
      "validTill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तक मान्य"])},
      "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अनिवार्य"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नाम"])},
      "isActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सक्रिय है"])},
      "isRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आवश्यक है"])},
      "clientGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ग्राहक समूह"])},
      "saveContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अनुबंध सहेजें"])},
      "selectActiveStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सक्रिय स्थिति चुनें"])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सक्रिय"])},
      "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निष्क्रिय"])},
      "selectRequiredStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आवश्यक स्थिति चुनें"])},
      "notRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आवश्यक नहीं"])},
      "editContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अनुबंध संपादित करें"])},
      "updateContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अनुबंध अपडेट करें"])},
      "contractDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अनुबंध विवरण"])}
    }
  },
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बंद करें"])},
  "notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सूचनाएं"])},
  "markAsRead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पढ़ा हुआ चिह्नित करें"])},
  "loadMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अधिक लोड करें"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खोजें"])},
  "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रीसेट"])},
  "selectlanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भाषा चुनें"])},
  "orm": {
    "order": {
      "syncOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आदेश समकालिक करें"])},
      "selectShop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दुकान चुनें"])},
      "orderId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आदेश ID"])},
      "orderDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आदेश तिथि"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ग्राहक"])},
      "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["राशि"])},
      "fulfillmentStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पूर्ति स्थिति"])},
      "deliveryId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डिलीवरी ID"])},
      "deliveryMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डिलीवरी विधि"])},
      "tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टैग"])}
    }
  },
  "purchase": {
    "supplier": {
      "addSupplier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रदायक जोड़ें"])},
      "supplierId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रदायक ID"])},
      "dropshipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ड्रॉपशिपिंग"])},
      "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वेबसाइट"])},
      "interface": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इंटरफ़ेस"])},
      "supplierName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रदायक का नाम"])},
      "searchBySupplierName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रदायक के नाम से खोजें"])}
    },
    "supplierOrder": {
      "totalSupplierOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कुल प्रदायक आदेश"])},
      "dateId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तिथि ID"])},
      "drop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ड्रॉप"])},
      "ids": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ग्राहक"])}
    }
  },
  "print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रिंट"])},
  "htmlTemplateConstructor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HTML टेम्पलेट कंस्ट्रक्टर"])},
  "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पूर्वावलोकन"])},
  "vendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विक्रेता"])},
  "createdBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["के द्वारा निर्मित"])},
  "updatedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["द्वारा अपडेट किया गया"])},
  "Articlefeaturesvalue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेख सुविधाएं मूल्य"])},
  "orderOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आदेशों का अवलोकन"])},
  "last15Order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अंतिम 15 आदेश"])},
  "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["चैनल"])},
  "articledetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेख विवरण"])},
  "descriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विवरण"])},
  "articleBundle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेख बंडल"])},
  "statistic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आँकड़े"])},
  "ship": {
    "deliveryNote": {
      "generateLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेबल उत्पन्न करें"])},
      "downloadLabels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेबल डाउनलोड करें"])},
      "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़िल्टर"])},
      "shippers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शिपर"])},
      "shippingArt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शिपिंग कला"])},
      "outboundStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आउटबाउंड स्थिति"])},
      "shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दुकान"])},
      "items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आइटम"])},
      "superAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सुपर व्यवस्थापक"])},
      "createShippment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शिपमेंट बनाएं"])},
      "onHold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["होल्ड पर"])},
      "editOutbound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आउटबाउंड संपादित करें"])},
      "createFullfilment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पूर्ति बनाएं"])},
      "filterIssued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मुद्दों को फ़िल्टर करें"])}
    },
    "shipping": {
      "bulkShippingUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["थोक शिपिंग अपडेट"])},
      "shippingOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शिपिंग का अवलोकन"])},
      "enableUpdateOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आदेश अपडेट सक्षम करें"])},
      "selectType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रकार चुनें"])},
      "digital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डिजिटल"])},
      "selectFulfilledStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पूर्ति स्थिति चुनें"])},
      "fulfilled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पूर्ति किया गया"])},
      "notFulfilled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पूर्ति नहीं की गई"])},
      "selectTrackingStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ट्रैकिंग स्थिति चुनें"])},
      "identcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पहचान कोड"])},
      "deliverNr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डिलीवरी संख्या"])},
      "extReferenceId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बाहरी संदर्भ आईडी"])},
      "updateOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आदेश अपडेट करें"])},
      "updateShipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शिपिंग अपडेट करें"])},
      "enableFulfillOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आदेश पूर्ति सक्षम करें"])},
      "fulfillOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आदेश को पूरा करें"])}
    }
  },
  "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["चुनें"])},
  "Not Created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निर्मित नहीं"])},
  "Open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खुला"])},
  "Confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पुष्टि"])},
  "In Process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रक्रियाधीन"])},
  "Shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भेजा गया"])},
  "clearFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़िल्टर साफ़ करें"])},
  "clearFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़िल्टर साफ़ करें"])},
  "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लागू करें"])},
  "dateFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तिथि फ़िल्टर"])},
  "filteredBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़िल्टर किया गया"])},
  "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मुद्रा"])},
  "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिप्पणी"])},
  "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़िल्टर"])},
  "totalShip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कुल शिपमेंट"])},
  "contact": {
    "contactO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संपर्क का अवलोकन"])},
    "customerN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ग्राहक संख्या"])},
    "customerC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ग्राहक श्रेणी"])},
    "customerG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ग्राहक समूह"])}
  },
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नाम"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फोन"])},
  "zipCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पिन कोड"])},
  "addChannelSKU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["चैनल SKU जोड़ें"])},
  "sku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SKU"])},
  "gtin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GTIN"])},
  "ean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EAN"])},
  "article": {
    "logdata": {
      "collieData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कॉल्ली डेटा"])},
      "addCollie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कॉल्ली जोड़ें"])},
      "editCollie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कॉल्ली संपादित करें"])}
    },
    "stock": {
      "warehouseArticles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["गोदाम लेख"])},
      "updateBuffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बफ़र अपडेट करें"])},
      "totalQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कुल मात्रा"])},
      "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संदर्भ"])},
      "availableQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपलब्ध मात्रा"])},
      "lastUpdatedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अंतिम बार अपडेट किया गया"])}
    }
  },
  "supplierorder": {
    "addOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आदेश जोड़ें"])},
    "refId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संदर्भ आईडी"])},
    "supOrdNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रदायक आदेश संख्या"])},
    "selectArticles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेख चुनें"])},
    "createOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आदेश बनाएं"])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आईडी"])},
    "saddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रदायक का पता"])},
    "iStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इनबाउंड स्थिति"])},
    "createInbound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इनबाउंड बनाएं"])},
    "inboundId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इनबाउंड आईडी"])}
  },
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रद्द करें"])},
  "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हो गया"])},
  "articleImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेख छवि"])},
  "syncImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["छवि समकालिक करें"])},
  "orderStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आदेश की स्थिति"])},
  "deliverynote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वितरण नोट"])},
  "deliveryStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वितरण स्थिति"])},
  "uploadImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["छवि अपलोड करें"])},
  "articleNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेख संख्या"])},
  "updateOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आदेश अपडेट करें"])},
  "updateExyternalOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बाहरी आदेश अपडेट करें"])},
  "printOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आदेश प्रिंट करें"])},
  "freePos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मुफ्त स्थान"])},
  "shippingNotRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शिपिंग की आवश्यकता नहीं"])},
  "noShippingArt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई शिपिंग कला उपलब्ध नहीं है"])},
  "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हटा दिया गया"])},
  "subTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उप-योग"])},
  "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["छूट"])},
  "taxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कर"])},
  "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कुल"])},
  "custInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संपर्क जानकारी"])},
  "shippingAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शिपिंग पता"])},
  "selectImages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["चित्र चुनें"])},
  "selectAllImages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी चित्र चुनें"])},
  "deleteImages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["चित्र हटाएं"])},
  "supplier": {
    "articlename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रदायक आइटम का नाम"])},
    "articlenumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेख संख्या"])},
    "delivery_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वितरण समय"])},
    "purchasePrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खरीद मूल्य"])}
  },
  "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल्य"])},
  "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपलब्ध"])},
  "paymentStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान की स्थिति"])},
  "orderpositions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आदेश की स्थिति"])},
  "editOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आदेश संपादित करें"])},
  "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपडेट"])},
  "addPackage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पैकेज जोड़ें"])},
  "inbound": {
    "fulfillerId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पूर्ति करने वाले का आईडी"])},
    "mercInboundNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["व्यापारी इनबाउंड नंबर"])},
    "inboundDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इनबाउंड तिथि"])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थान"])},
    "warehouseName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["गोदाम का नाम"])},
    "inboundDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इनबाउंड विवरण"])},
    "addPackages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पैकेज जोड़ें"])}
  },
  "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मात्रा"])},
  "StockTransfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्टॉक ट्रांसफर"])},
  "createFulfillment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पूर्ति बनाएं"])},
  "change client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ग्राहक बदलें"])},
  "selectcurrency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मुद्रा चुनें"])},
  "stocks": {
    "addWarehouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["गोदाम जोड़ें"])},
    "saveWarehouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["गोदाम सहेजें"])}
  },
  "invoice": {
    "invoiceBuilder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["चालान निर्माता"])},
    "nextStep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अगला चरण"])},
    "prevStep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पिछला चरण"])},
    "selectCust": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ग्राहक चुनें"])},
    "searchCust": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ग्राहक खोजें"])},
    "clientDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ग्राहक विवरण"])}
  },
  "addArticle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेख जोड़ें"])},
  "saveArticle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेख सहेजें"])},
  "articleType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेख प्रकार"])},
  "uploadArticleImages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेख छवियाँ अपलोड करें"])},
  "addVariant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वैरिएंट जोड़ें"])},
  "variants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वैरिएंट्स"])},
  "selling_price_net": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बिक्री मूल्य"])},
  "globalSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वैश्विक सेटिंग्स"])},
  "noranges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संख्या सीमाएँ"])},
  "prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रारंभिक"])},
  "currentNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वर्तमान संख्या"])},
  "suffix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सुफिक्स"])},
  "maximum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अधिकतम"])},
  "global": {
    "Invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["चालान"])},
    "Credit Note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रेडिट नोट"])},
    "Order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आदेश"])},
    "Quotation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोटेशन"])},
    "Article": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेख"])},
    "Customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ग्राहक"])},
    "Price Inquiry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल्य पूछताछ"])},
    "Incoming Invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इनकमिंग चालान"])},
    "Transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थानांतरण"])},
    "Returns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वापसी"])},
    "Ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टिकट"])},
    "Pick List": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पिक सूची"])},
    "Subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सदस्यता"])},
    "Operation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऑपरेशन"])},
    "Ressource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संसाधन"])},
    "Production Order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उत्पादन आदेश"])},
    "Production Supply Order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उत्पादन आपूर्ति आदेश"])},
    "Production Transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उत्पादन स्थानांतरण"])},
    "Production Batch Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उत्पादन बैच संख्या"])},
    "Cancellation Invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["चालान रद्द करें"])},
    "Cancellation Credit Note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रेडिट नोट रद्द करें"])},
    "Article Project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेख परियोजना"])},
    "Claim Order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दावा आदेश"])}
  }
}