export default {
  "welcomeMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenue chez Dropy EBM"])},
  "registerButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscrivez-vous"])},
  "clientRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créez votre compte gratuit ici"])},
  "completeRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compléter l'inscription"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langue"])},
  "fName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
  "lName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
  "cName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l'entreprise"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
  "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TVA"])},
  "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rue"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
  "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays"])},
  "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code postal"])},
  "radius": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rayon"])},
  "agreeTerm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J'accepte les termes et conditions du site"])},
  "accountCreateMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre compte a été créé. Veuillez vérifier votre e-mail pour vérifier votre compte."])},
  "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succès"])},
  "selectClientGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner le groupe de clients"])},
  "Supplier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fournisseur"])},
  "Suppliers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fournisseurs"])},
  "Supplierorders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commandes de fournisseurs"])},
  "Dropshipper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dropshipper"])},
  "Manufactor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabricant"])},
  "Craftsman": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artisan"])},
  "Carrier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transporteur"])},
  "Agency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agence"])},
  "Integrator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intégrateur"])},
  "requiredField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Champ requis"])},
  "uploadFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrement de l'entreprise"])},
  "loginHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connectez-vous à votre compte"])},
  "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié ?"])},
  "logIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
  "otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OTP"])},
  "noEmailAccountError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun compte n'est associé à cet e-mail"])},
  "invalidPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe incorrect"])},
  "Deliveryaddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse de livraison"])},
  "Invoiceaddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse de facturation"])},
  "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commande"])},
  "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commandes"])},
  "Orderdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de commande"])},
  "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expédition"])},
  "Shippingdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'expédition"])},
  "deliverynotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes de livraison"])},
  "warehouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrepôt"])},
  "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres"])},
  "stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stock"])},
  "salution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salutation"])},
  "deliveryaddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse de livraison"])},
  "shippingdetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails de l'expédition"])},
  "shippingart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type d'expédition"])},
  "Shippingarts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Types d'expédition"])},
  "shipper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transporteur"])},
  "shipping_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'expédition"])},
  "shipperdetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails du transporteur"])},
  "Articlefeatures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caractéristiques de l'article"])},
  "HOUSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maison"])},
  "deliveryPlacement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emplacement de livraison"])},
  "packageRemoval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retrait du colis"])},
  "YES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])},
  "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])},
  "PLACE_OF_USE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieu d'utilisation"])},
  "TWENTYFOUR_HOUR_SERVICE_MO_FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service 24h Lun.-Ven."])},
  "TWENTYFOUR_HOUR_SERVICE_SA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service 24h Sam."])},
  "FOURTYEIGHT_HOUR_SERVICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service 48h"])},
  "DELIVERY_ON_DESIRED_DATE_MO_FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livraison à la date souhaitée Lun.-Ven."])},
  "DELIVERY_ON_DESIRED_DATE_SA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livraison à la date souhaitée Sam."])},
  "AFTER_WORK_DELIVERY_SERVICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service de livraison après travail"])},
  "EXPRESS_ASSEMBLY_SERVICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service de montage express"])},
  "shipmentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Méthode d'expédition"])},
  "transferPoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Point de transfert"])},
  "NORMAL_SHIPMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expédition normale"])},
  "process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processus"])},
  "trackandtrace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivi"])},
  "packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colis"])},
  "collie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colis"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
  "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
  "save_shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer les données d'expédition"])},
  "save_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer l'adresse"])},
  "shippingoptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options d'expédition"])},
  "COLLECTION_AT_DEPOT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collecte au dépôt"])},
  "HUB_LOEHNE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HUB Löhne"])},
  "HUB_ANSBACH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HUB Ansbach"])},
  "HUB_GOLUSKI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HUB Goluski"])},
  "ZUP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZUP Mosina PL"])},
  "DDL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DDL"])},
  "foridentcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivi pour"])},
  "shippingpositions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positions d'expédition"])},
  "MS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mme"])},
  "UNKNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inconnu"])},
  "COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprise"])},
  "MR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["M."])},
  "DIVERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Divers"])},
  "create delivery order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une commande d'expédition"])},
  "save address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer l'adresse"])},
  "MyInterfaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes interfaces"])},
  "usedinterfaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interfaces utilisées"])},
  "availableinterfaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interfaces disponibles"])},
  "book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réserver"])},
  "configure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurer"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder"])},
  "startjob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commencer la synchronisation"])},
  "Table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tableau"])},
  "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur"])},
  "option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Option"])},
  "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source"])},
  "lastupdatedate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière mise à jour"])},
  "source value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur source"])},
  "statustype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de statut"])},
  "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
  "Contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts"])},
  "articles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Articles"])},
  "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégories"])},
  "Articleprojects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Création d'articles"])},
  "producer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabricant"])},
  "Warehouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrepôt"])},
  "Returns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retours"])},
  "Value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur"])},
  "shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expédié"])},
  "not shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non expédié"])},
  "saveCollieDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer le colis après avoir ajouté le mappage"])},
  "saveMappingDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer le mappage ici"])},
  "superAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dropy Admin"])},
  "articleTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèle"])},
  "shipmentFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expédition pour"])},
  "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateurs"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnexion"])},
  "reporting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyses"])},
  "TrackandTrace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivi & Traçabilité"])},
  "shipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expédition"])},
  "shipments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expéditions"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous"])},
  "recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récemment ajouté"])},
  "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attente"])},
  "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actif"])},
  "delivered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livré"])},
  "pissue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colis avec problème"])},
  "returns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retours"])},
  "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aujourd'hui"])},
  "yeasterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier"])},
  "thisWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette semaine"])},
  "lastWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La semaine dernière"])},
  "last7Days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les 7 derniers jours"])},
  "last14Days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les 14 derniers jours"])},
  "last30Days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les 30 derniers jours"])},
  "thisMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce mois-ci"])},
  "lastMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mois dernier"])},
  "last3Month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les 3 derniers mois"])},
  "last6Month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les 6 derniers mois"])},
  "last12Month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les 12 derniers mois"])},
  "notPrepared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non préparé"])},
  "returned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retourné"])},
  "deliveryException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exception de livraison"])},
  "issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problème"])},
  "trackingNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de suivi"])},
  "carrierR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transporteur (Référence)"])},
  "lEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernier événement"])},
  "lInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière info"])},
  "dateAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'ajout"])},
  "noFilterApplied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun filtre appliqué"])},
  "OverviewlastDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperçu des 14 derniers jours"])},
  "parcelAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colis ajouté"])},
  "topCarriers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top transporteurs (basé sur le volume)"])},
  "pendingorders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commandes en attente"])},
  "profit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profit"])},
  "orderstoday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commandes aujourd'hui"])},
  "earningstoday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gains aujourd'hui"])},
  "Order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commande"])},
  "description_success_connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion réussie"])},
  "shippinglog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Journaux d'expédition"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
  "LABEL_CREATED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étiquette créée"])},
  "selectArticleType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner le type d'article"])},
  "pim": {
    "articleoverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperçu de l'article"])},
    "article": {
      "basicdetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Données de base"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l'article"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description de l'article"])},
      "logdata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Données logistiques"])},
      "prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix"])},
      "images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Images"])},
      "syncArticle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synchroniser les articles"])}
    },
    "template": {
      "articleProjectDescriptionTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèles de description de projet d'article"])},
      "createNewTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un nouveau modèle"])},
      "saveTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer le modèle"])},
      "templateName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du modèle"])}
    },
    "articleCreation": {
      "articleProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projet d'article"])},
      "addArticleProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un projet d'article"])},
      "saveProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer le projet"])}
    }
  },
  "supplier": {
    "articlename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l'article du fournisseur"])}
  },
  "tt": {
    "noFilterApplied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun filtre défini"])},
    "TotalShippings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total des expéditions"])}
  },
  "microsoftLogIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion avec Microsoft 365"])},
  "dropyAdmin": {
    "contract": {
      "contractOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperçu des contrats"])},
      "addContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un contrat"])},
      "contractName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du contrat"])},
      "validFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valide à partir du"])},
      "validTill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valide jusqu'au"])},
      "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requis"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
      "isActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Est actif"])},
      "isRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Est requis"])},
      "clientGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe de clients"])},
      "saveContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer le contrat"])},
      "selectActiveStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner le statut actif"])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actif"])},
      "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactif"])},
      "selectRequiredStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner le statut requis"])},
      "notRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non requis"])},
      "editContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le contrat"])},
      "updateContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour le contrat"])},
      "contractDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails du contrat"])}
    }
  },
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])},
  "notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
  "markAsRead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marquer comme lu"])},
  "loadMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charger plus"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche"])},
  "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser"])},
  "selectlanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner la langue"])},
  "orm": {
    "order": {
      "syncOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synchroniser les commandes"])},
      "selectShop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner la boutique"])},
      "orderId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de commande"])},
      "orderDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de commande"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client"])},
      "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
      "fulfillmentStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut de réalisation"])},
      "deliveryId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de livraison"])},
      "deliveryMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Méthode de livraison"])},
      "tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étiquette"])}
    }
  },
  "purchase": {
    "supplier": {
      "addSupplier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un fournisseur"])},
      "supplierId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID du fournisseur"])},
      "dropshipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dropshipping"])},
      "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site web"])},
      "interface": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interface"])},
      "supplierName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du fournisseur"])},
      "searchBySupplierName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher par nom du fournisseur"])}
    },
    "supplierOrder": {
      "totalSupplierOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total des commandes fournisseurs"])},
      "dateId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de la date"])},
      "drop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goutte"])},
      "ids": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDS"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client"])}
    }
  },
  "print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprimer"])},
  "htmlTemplateConstructor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Constructeur de modèles HTML"])},
  "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperçu"])},
  "vendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendeur"])},
  "createdBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créé par"])},
  "updatedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis à jour par"])},
  "Articlefeaturesvalue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur des caractéristiques de l'article"])},
  "orderOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperçu des commandes"])},
  "last15Order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les 15 dernières commandes"])},
  "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canal"])},
  "articledetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails de l'article"])},
  "descriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descriptions"])},
  "articleBundle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pack d'articles"])},
  "statistic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistiques"])},
  "ship": {
    "deliveryNote": {
      "generateLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Générer une étiquette"])},
      "downloadLabels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger les étiquettes"])},
      "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtre"])},
      "shippers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transporteurs"])},
      "shippingArt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type d'expédition"])},
      "outboundStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut de sortie"])},
      "shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boutique"])},
      "items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Articles"])},
      "superAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Super Admin"])},
      "createShippment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une expédition"])},
      "onHold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attente"])},
      "editOutbound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier la sortie"])},
      "createFullfilment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une réalisation"])},
      "filterIssued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrer les problèmes"])}
    },
    "shipping": {
      "bulkShippingUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mise à jour massive des expéditions"])},
      "shippingOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperçu des expéditions"])},
      "enableUpdateOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer la mise à jour de la commande"])},
      "selectType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner le type"])},
      "digital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numérique"])},
      "selectFulfilledStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner le statut de réalisation"])},
      "fulfilled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réalisé"])},
      "notFulfilled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non réalisé"])},
      "selectTrackingStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner le statut de suivi"])},
      "identcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code identifiant"])},
      "deliverNr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de livraison"])},
      "extReferenceId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de référence externe"])},
      "updateOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour la commande"])},
      "updateShipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour l'expédition"])},
      "enableFulfillOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer la réalisation de la commande"])},
      "fulfillOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réaliser la commande"])}
    }
  },
  "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner"])},
  "Not Created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non créé"])},
  "Open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvert"])},
  "Confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmé"])},
  "In Process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cours"])},
  "Shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expédié"])},
  "clearFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effacer les filtres"])},
  "clearFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effacer le filtre"])},
  "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appliquer"])},
  "dateFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtre de date"])},
  "filteredBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtré par"])},
  "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devise"])},
  "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire"])},
  "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtre"])},
  "totalShip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total des expéditions"])},
  "contact": {
    "contactO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperçu des contacts"])},
    "customerN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de client"])},
    "customerC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie de client"])},
    "customerG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe de clients"])}
  },
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone"])},
  "zipCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code postal"])},
  "addChannelSKU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un SKU de canal"])},
  "sku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SKU"])},
  "gtin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GTIN"])},
  "ean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EAN"])},
  "article": {
    "logdata": {
      "collieData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Données de colis"])},
      "addCollie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter des colis"])},
      "editCollie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier des colis"])}
    },
    "stock": {
      "warehouseArticles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Articles en stock"])},
      "updateBuffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour le tampon"])},
      "totalQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantité totale"])},
      "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Référence"])},
      "availableQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantité disponible"])},
      "lastUpdatedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière mise à jour"])}
    }
  },
  "supplierorder": {
    "addOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une commande"])},
    "refId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de référence"])},
    "supOrdNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de commande fournisseur"])},
    "selectArticles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner les articles"])},
    "createOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une commande"])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "saddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse du fournisseur"])},
    "iStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut d'entrée"])},
    "createInbound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une entrée"])},
    "inboundId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID d'entrée"])}
  },
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
  "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminé"])},
  "articleImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image de l'article"])},
  "syncImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synchroniser l'image"])},
  "orderStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut de la commande"])},
  "deliverynote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bon de livraison"])},
  "deliveryStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut de la livraison"])},
  "uploadImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger l'image"])},
  "articleNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de l'article"])},
  "updateOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour la commande"])},
  "updateExyternalOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour la commande externe"])},
  "printOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprimer la commande"])},
  "freePos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positions libres"])},
  "shippingNotRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expédition non requise"])},
  "noShippingArt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun type d'expédition disponible"])},
  "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimé"])},
  "subTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sous-total"])},
  "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remise"])},
  "taxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxes"])},
  "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
  "custInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations de contact"])},
  "shippingAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse de livraison"])},
  "selectImages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner les images"])},
  "selectAllImages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner toutes les images"])},
  "deleteImages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer les images"])},
  "selectcurrency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner la devise"])},
  "stocks": {
    "addWarehouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un entrepôt"])},
    "saveWarehouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer l'entrepôt"])}
  },
  "invoice": {
    "invoiceBuilder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Générateur de factures"])},
    "nextStep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étape suivante"])},
    "prevStep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étape précédente"])},
    "selectCust": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner un client"])},
    "searchCust": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher un client"])},
    "clientDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails du client"])}
  },
  "addArticle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un article"])},
  "saveArticle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer l'article"])},
  "articleType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type d'article"])},
  "uploadArticleImages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger des images d'articles"])},
  "addVariant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une variante"])},
  "variants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variantes"])},
  "selling_price_net": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix de vente"])},
  "globalSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres globaux"])},
  "noranges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plages de numéros"])},
  "prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Préfixe"])},
  "currentNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro actuel"])},
  "suffix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suffixe"])},
  "maximum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum"])},
  "global": {
    "Invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facture"])},
    "Credit Note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note de crédit"])},
    "Order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commande"])},
    "Quotation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devis"])},
    "Article": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Article"])},
    "Customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client"])},
    "Price Inquiry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demande de prix"])},
    "Incoming Invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facture entrante"])},
    "Transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfert"])},
    "Returns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retours"])},
    "Ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billet"])},
    "Pick List": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste de prélèvement"])},
    "Subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement"])},
    "Operation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opération"])},
    "Ressource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ressource"])},
    "Production Order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordre de production"])},
    "Production Supply Order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordre d'approvisionnement"])},
    "Production Transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfert de production"])},
    "Production Batch Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de lot de production"])},
    "Cancellation Invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facture annulée"])},
    "Cancellation Credit Note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note de crédit annulée"])},
    "Article Project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projet d'article"])},
    "Claim Order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordre de réclamation"])}
  }
}