export default {
  "welcomeMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welkom bij Dropy EBM"])},
  "registerButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registreer jezelf"])},
  "clientRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak hier je gratis account aan"])},
  "completeRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registratie voltooien"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taal"])},
  "fName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voornaam"])},
  "lName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achternaam"])},
  "cName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijfsnaam"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
  "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Btw"])},
  "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straat"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stad"])},
  "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
  "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postcode"])},
  "radius": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straal"])},
  "agreeTerm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ik ga akkoord met de algemene voorwaarden"])},
  "accountCreateMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je account is aangemaakt. Controleer je e-mail om je account te verifiëren."])},
  "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])},
  "selectClientGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer klantgroep"])},
  "Supplier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leverancier"])},
  "Suppliers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leveranciers"])},
  "Supplierorders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellingen van leveranciers"])},
  "Dropshipper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dropshipper"])},
  "Manufactor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrikant"])},
  "Craftsman": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ambachtsman"])},
  "Carrier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vervoerder"])},
  "Agency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agentschap"])},
  "Integrator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrator"])},
  "requiredField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veld is verplicht"])},
  "uploadFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijfsregistratie"])},
  "loginHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in op je account"])},
  "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord vergeten?"])},
  "logIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inloggen"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord"])},
  "otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OTP"])},
  "noEmailAccountError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is geen account gekoppeld aan dit e-mailadres"])},
  "invalidPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongeldig wachtwoord"])},
  "Deliveryaddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezorgadres"])},
  "Invoiceaddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Factuuradres"])},
  "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestelling"])},
  "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellingen"])},
  "Orderdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besteldatum"])},
  "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzending"])},
  "Shippingdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzenddatum"])},
  "deliverynotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afleverbonnen"])},
  "warehouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magazijn"])},
  "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instellingen"])},
  "stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voorraad"])},
  "salution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanhef"])},
  "deliveryaddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezorgadres"])},
  "shippingdetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzenddetails"])},
  "shippingart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzendmethode"])},
  "Shippingarts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzendmethoden"])},
  "shipper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vervoerder"])},
  "shipping_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzenddatum"])},
  "shipperdetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vervoerdersdetails"])},
  "Articlefeatures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikeleigenschappen"])},
  "HOUSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huis"])},
  "deliveryPlacement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leveringsplaats"])},
  "packageRemoval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pakketverwijdering"])},
  "YES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
  "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nee"])},
  "PLACE_OF_USE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikslocatie"])},
  "TWENTYFOUR_HOUR_SERVICE_MO_FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24-uurs service ma.-vr."])},
  "TWENTYFOUR_HOUR_SERVICE_SA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24-uurs service za."])},
  "FOURTYEIGHT_HOUR_SERVICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["48-uurs service"])},
  "DELIVERY_ON_DESIRED_DATE_MO_FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Levering op gewenste datum ma.-vr."])},
  "DELIVERY_ON_DESIRED_DATE_SA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Levering op gewenste datum za."])},
  "AFTER_WORK_DELIVERY_SERVICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezorgservice na werktijd"])},
  "EXPRESS_ASSEMBLY_SERVICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Express montageservice"])},
  "shipmentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzendmethode"])},
  "transferPoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overdrachtsplaats"])},
  "NORMAL_SHIPMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normale verzending"])},
  "process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proces"])},
  "trackandtrace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pakketstatus"])},
  "packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pakketten"])},
  "collie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colli"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actie"])},
  "save_shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzendgegevens opslaan"])},
  "save_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres opslaan"])},
  "shippingoptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzendopties"])},
  "COLLECTION_AT_DEPOT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ophalen bij depot"])},
  "HUB_LOEHNE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HUB Löhne"])},
  "HUB_ANSBACH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HUB Ansbach"])},
  "HUB_GOLUSKI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HUB Goluski"])},
  "ZUP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZUP Mosina PL"])},
  "DDL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DDL"])},
  "foridentcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Track en Trace voor"])},
  "shippingpositions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzendposities"])},
  "MS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mevr."])},
  "UNKNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onbekend"])},
  "COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijf"])},
  "MR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dhr."])},
  "DIVERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diversen"])},
  "create delivery order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak verzendopdracht aan"])},
  "save address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres opslaan"])},
  "MyInterfaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mijn interfaces"])},
  "usedinterfaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikte interfaces"])},
  "availableinterfaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschikbare interfaces"])},
  "book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boeken"])},
  "configure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configureren"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opslaan"])},
  "startjob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synchronisatie starten"])},
  "Table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabel"])},
  "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waarde"])},
  "option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optie"])},
  "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bron"])},
  "lastupdatedate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laatste update"])},
  "source value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bronwaarde"])},
  "statustype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statustype"])},
  "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acties"])},
  "Contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacten"])},
  "articles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikelen"])},
  "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorieën"])},
  "Articleprojects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikel Creatie"])},
  "producer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrikant"])},
  "Warehouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magazijn"])},
  "Returns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retouren"])},
  "Value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waarde"])},
  "shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzonden"])},
  "not shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet verzonden"])},
  "saveCollieDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sla de colli op na het toevoegen van mapping"])},
  "saveMappingDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sla de mapping hier op"])},
  "superAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dropy Admin"])},
  "articleTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sjabloon"])},
  "shipmentFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzending voor"])},
  "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikers"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitloggen"])},
  "reporting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyses"])},
  "TrackandTrace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Track & Trace"])},
  "shipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzending"])},
  "shipments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zendingen"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle"])},
  "recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recent toegevoegd"])},
  "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In behandeling"])},
  "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actief"])},
  "delivered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geleverd"])},
  "pissue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pakket met probleem"])},
  "returns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retouren"])},
  "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vandaag"])},
  "yeasterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gisteren"])},
  "thisWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze week"])},
  "lastWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorige week"])},
  "last7Days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laatste 7 dagen"])},
  "last14Days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laatste 14 dagen"])},
  "last30Days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laatste 30 dagen"])},
  "thisMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze maand"])},
  "lastMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorige maand"])},
  "last3Month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laatste 3 maanden"])},
  "last6Month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laatste 6 maanden"])},
  "last12Month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laatste 12 maanden"])},
  "notPrepared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet voorbereid"])},
  "returned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geretourneerd"])},
  "deliveryException": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitzondering levering"])},
  "issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Probleem"])},
  "trackingNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volgnummer"])},
  "carrierR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vervoerder (Referentie)"])},
  "lEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laatste gebeurtenis"])},
  "lInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laatste info"])},
  "dateAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum toegevoegd"])},
  "noFilterApplied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen filters toegepast"])},
  "OverviewlastDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overzicht van de laatste 14 dagen"])},
  "parcelAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pakket toegevoegd"])},
  "topCarriers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top vervoerders (op basis van volume)"])},
  "pendingorders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In behandeling zijnde bestellingen"])},
  "profit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Winst"])},
  "orderstoday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellingen vandaag"])},
  "earningstoday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verdiensten vandaag"])},
  "Order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestelling"])},
  "description_success_connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbinding succesvol"])},
  "shippinglog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzendlogboeken"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bericht"])},
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
  "LABEL_CREATED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Label aangemaakt"])},
  "selectArticleType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer artikeltype"])},
  "pim": {
    "articleoverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikeloverzicht"])},
    "article": {
      "basicdetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stamgegevens"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikelnaam"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikelbeschrijving"])},
      "logdata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logistieke gegevens"])},
      "prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijzen"])},
      "images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afbeeldingen"])},
      "syncArticle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikelen synchroniseren"])}
    },
    "template": {
      "articleProjectDescriptionTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sjablonen voor artikelprojectbeschrijvingen"])},
      "createNewTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuw sjabloon maken"])},
      "saveTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sjabloon opslaan"])},
      "templateName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sjabloonnaam"])}
    },
    "articleCreation": {
      "articleProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikelproject"])},
      "addArticleProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikelproject toevoegen"])},
      "saveProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project opslaan"])}
    }
  },
  "supplier": {
    "articlename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam leverancierartikel"])}
  },
  "tt": {
    "noFilterApplied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen filter ingesteld"])},
    "TotalShippings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totaal aantal zendingen"])}
  },
  "microsoftLogIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inloggen met Microsoft 365"])},
  "dropyAdmin": {
    "contract": {
      "contractOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contractoverzicht"])},
      "addContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract toevoegen"])},
      "contractName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contractnaam"])},
      "validFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geldig vanaf"])},
      "validTill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geldig tot"])},
      "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vereist"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
      "isActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is actief"])},
      "isRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is vereist"])},
      "clientGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klantgroep"])},
      "saveContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract opslaan"])},
      "selectActiveStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer actieve status"])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actief"])},
      "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactief"])},
      "selectRequiredStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer vereiste status"])},
      "notRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet vereist"])},
      "editContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract bewerken"])},
      "updateContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract bijwerken"])},
      "contractDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contractdetails"])}
    }
  },
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sluiten"])},
  "notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meldingen"])},
  "markAsRead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markeer als gelezen"])},
  "loadMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meer laden"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoeken"])},
  "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resetten"])},
  "selectlanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer taal"])},
  "orm": {
    "order": {
      "syncOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellingen synchroniseren"])},
      "selectShop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer winkel"])},
      "orderId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestel-ID"])},
      "orderDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besteldatum"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klant"])},
      "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrag"])},
      "fulfillmentStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status van voltooiing"])},
      "deliveryId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leverings-ID"])},
      "deliveryMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leveringsmethode"])},
      "tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag"])}
    }
  },
  "purchase": {
    "supplier": {
      "addSupplier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leverancier toevoegen"])},
      "supplierId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leverancier-ID"])},
      "dropshipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dropshipping"])},
      "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website"])},
      "interface": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interface"])},
      "supplierName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam leverancier"])},
      "searchBySupplierName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoeken op leveranciersnaam"])}
    },
    "supplierOrder": {
      "totalSupplierOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totaal aantal leveranciersorders"])},
      "dateId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum-ID"])},
      "drop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Druppel"])},
      "ids": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDS"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klant"])}
    }
  },
  "print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afdrukken"])},
  "htmlTemplateConstructor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HTML-sjabloonbouwer"])},
  "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voorbeeld"])},
  "vendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkoper"])},
  "createdBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemaakt door"])},
  "updatedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bijgewerkt door"])},
  "Articlefeaturesvalue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waarde van artikeleigenschappen"])},
  "orderOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellingenoverzicht"])},
  "last15Order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laatste 15 bestellingen"])},
  "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanaal"])},
  "articledetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikeldetails"])},
  "descriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschrijvingen"])},
  "articleBundle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikelbundel"])},
  "statistic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistieken"])},
  "ship": {
    "deliveryNote": {
      "generateLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Label genereren"])},
      "downloadLabels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Labels downloaden"])},
      "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
      "shippers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verladers"])},
      "shippingArt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzendmethode"])},
      "outboundStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitgaande status"])},
      "shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Winkel"])},
      "items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikelen"])},
      "superAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Super Admin"])},
      "createShippment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzending aanmaken"])},
      "onHold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In de wacht"])},
      "editOutbound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitgaande zending bewerken"])},
      "createFullfilment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voltooiing aanmaken"])},
      "filterIssued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter problemen"])}
    },
    "shipping": {
      "bulkShippingUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulkverzending bijwerken"])},
      "shippingOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overzicht van verzending"])},
      "enableUpdateOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestelling bijwerken inschakelen"])},
      "selectType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer type"])},
      "digital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digitaal"])},
      "selectFulfilledStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer voltooiingsstatus"])},
      "fulfilled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voltooid"])},
      "notFulfilled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet voltooid"])},
      "selectTrackingStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer trackingstatus"])},
      "identcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identcode"])},
      "deliverNr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leveringsnr."])},
      "extReferenceId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Externe referentie-ID"])},
      "updateOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestelling bijwerken"])},
      "updateShipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzending bijwerken"])},
      "enableFulfillOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voltooiing inschakelen"])},
      "fulfillOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestelling voltooien"])}
    }
  },
  "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer"])},
  "Not Created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet aangemaakt"])},
  "Open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])},
  "Confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevestigd"])},
  "In Process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In behandeling"])},
  "Shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzonden"])},
  "clearFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filters wissen"])},
  "clearFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter wissen"])},
  "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toepassen"])},
  "dateFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datumfilter"])},
  "filteredBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gefilterd door"])},
  "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valuta"])},
  "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opmerking"])},
  "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
  "totalShip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totaal aantal zendingen"])},
  "contact": {
    "contactO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactoverzicht"])},
    "customerN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klantnummer"])},
    "customerC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klantcategorie"])},
    "customerG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klantengroep"])}
  },
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefoon"])},
  "zipCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postcode"])},
  "addChannelSKU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanaal-SKU toevoegen"])},
  "sku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SKU"])},
  "gtin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GTIN"])},
  "ean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EAN"])},
  "article": {
    "logdata": {
      "collieData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collie-gegevens"])},
      "addCollie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collies toevoegen"])},
      "editCollie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collies bewerken"])}
    },
    "stock": {
      "warehouseArticles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magazijnartikelen"])},
      "updateBuffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buffer bijwerken"])},
      "totalQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale hoeveelheid"])},
      "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referentie"])},
      "availableQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschikbare hoeveelheid"])},
      "lastUpdatedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laatst bijgewerkt op"])}
    }
  },
  "supplierorder": {
    "addOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order toevoegen"])},
    "refId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referentie-ID"])},
    "supOrdNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leveranciersordernummer"])},
    "selectArticles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikelen selecteren"])},
    "createOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order aanmaken"])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "saddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leveranciersadres"])},
    "iStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inkomende status"])},
    "createInbound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inkomende zending aanmaken"])},
    "inboundId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inkomende zending ID"])}
  },
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuleren"])},
  "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klaar"])},
  "articleImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikelafbeelding"])},
  "syncImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afbeelding synchroniseren"])},
  "orderStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestelstatus"])},
  "deliverynote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leveringsnota"])},
  "deliveryStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leveringsstatus"])},
  "uploadImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afbeelding uploaden"])},
  "articleNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikelnummer"])},
  "updateOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestelling bijwerken"])},
  "updateExyternalOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Externe bestelling bijwerken"])},
  "printOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestelling afdrukken"])},
  "freePos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrije posities"])},
  "shippingNotRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzending niet vereist"])},
  "noShippingArt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen verzendmethode beschikbaar"])},
  "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijderd"])},
  "subTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtotaal"])},
  "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korting"])},
  "taxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belastingen"])},
  "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totaal"])},
  "custInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactinformatie"])},
  "shippingAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzendadres"])},
  "selectImages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afbeeldingen selecteren"])},
  "selectAllImages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer alle afbeeldingen"])},
  "deleteImages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afbeeldingen verwijderen"])},
  "selectcurrency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valuta selecteren"])},
  "stocks": {
    "addWarehouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magazijn toevoegen"])},
    "saveWarehouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magazijn opslaan"])}
  },
  "invoice": {
    "invoiceBuilder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Factuurbouwer"])},
    "nextStep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volgende stap"])},
    "prevStep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorige stap"])},
    "selectCust": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klant selecteren"])},
    "searchCust": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klant zoeken"])},
    "clientDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klantgegevens"])}
  },
  "addArticle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikel toevoegen"])},
  "saveArticle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikel opslaan"])},
  "articleType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikeltype"])},
  "uploadArticleImages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikelafbeeldingen uploaden"])},
  "addVariant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variant toevoegen"])},
  "variants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varianten"])},
  "selling_price_net": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkoopprijs"])},
  "globalSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Globale instellingen"])},
  "noranges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nummerreeksen"])},
  "prefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prefix"])},
  "currentNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huidig nummer"])},
  "suffix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suffix"])},
  "maximum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum"])},
  "global": {
    "Invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Factuur"])},
    "Credit Note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creditnota"])},
    "Order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestelling"])},
    "Quotation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offerte"])},
    "Article": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikel"])},
    "Customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klant"])},
    "Price Inquiry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijsaanvraag"])},
    "Incoming Invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Binnenkomende factuur"])},
    "Transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overdracht"])},
    "Returns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retouren"])},
    "Ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket"])},
    "Pick List": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Picklijst"])},
    "Subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement"])},
    "Operation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operatie"])},
    "Ressource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hulpbron"])},
    "Production Order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Productieorder"])},
    "Production Supply Order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Productieleveringsorder"])},
    "Production Transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Productieoverdracht"])},
    "Production Batch Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Productiebatchnummer"])},
    "Cancellation Invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geannuleerde factuur"])},
    "Cancellation Credit Note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geannuleerde creditnota"])},
    "Article Project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikelproject"])},
    "Claim Order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestelling claimen"])}
  }
}