import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import i18n from './i18n'
import VueApexCharts from "vue3-apexcharts";
import VueSweetalert2 from 'vue-sweetalert2';
import Notifications from '@kyvg/vue3-notification'
import 'sweetalert2/dist/sweetalert2.min.css';
import JsonViewer from "vue3-json-viewer";
import "vue3-json-viewer/dist/index.css";
import BaseBlock from './layouts/BaseBlock.vue';
import BaseNavigation from './layouts/BaseNavigation.vue';
import BasePageHeading from './layouts/BasePageHeading.vue';
import CKEditor from '@ckeditor/ckeditor5-vue';
import Vue3SimpleHtml2pdf from "vue3-simple-html2pdf";

import 'froala-editor/js/plugins.pkgd.min.js';
import 'froala-editor/js/third_party/embedly.min';
import 'froala-editor/js/third_party/font_awesome.min';
import 'froala-editor/js/third_party/spell_checker.min';
import 'froala-editor/js/third_party/image_tui.min';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/froala_style.min.css';

import VueFroala from 'vue-froala-wysiwyg';


import * as bootstrap from "bootstrap";
import CustomTheme from './Utils/Theme';
window.CustomTheme = CustomTheme
window.bootstrap = bootstrap;
loadFonts()

const app = createApp(App);

app.component("BaseBlock", BaseBlock);
app.component("BaseNavigation", BaseNavigation);
app.component("BasePageHeading", BasePageHeading);


app.use(i18n);
app.use(VueApexCharts);
app.use(router);
app.use(Vue3SimpleHtml2pdf);
app.use(JsonViewer);
app.use(store);
app.use(vuetify);
app.use(Notifications);
app.use(VueSweetalert2);
app.use(CKEditor)
app.use(VueFroala);
app.mount("#app");



// createApp(App)
//   .use(i18n)
//   .use(VueApexCharts)
//   .use(router)
//   .use(JsonViewer)
//   .use(store)
//   .use(vuetify)
//   .use(Notifications)
//   .use(VueSweetalert2)
//   .mount('#app')
